import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
// Import local images if not using public folder
import image1 from '../PageBuilder/SectionBuilder/SectionHero/img/banner1.jpg';
import image2 from '../PageBuilder/SectionBuilder/SectionHero/img/banner2.jpg';
import image3 from '../PageBuilder/SectionBuilder/SectionHero/img/banner3.jpg';
import hero4 from '../PageBuilder/SectionBuilder/SectionHero/img/banner4.jpg';
import hero5 from '../PageBuilder/SectionBuilder/SectionHero/img/banner5.jpg';
import hero6 from '../PageBuilder/SectionBuilder/SectionHero/img/banner6.jpg';
import hero7 from '../PageBuilder/SectionBuilder/SectionHero/img/banner7.jpg';
import hero8 from '../PageBuilder/SectionBuilder/SectionHero/img/banner8.jpg';
import hero10 from '../PageBuilder/SectionBuilder/SectionHero/img/banner10.jpg';
import hero11 from '../PageBuilder/SectionBuilder/SectionHero/img/banner11.jpg';
import hero12 from '../PageBuilder/SectionBuilder/SectionHero/img/banner12.jpg';
import hero13 from '../PageBuilder/SectionBuilder/SectionHero/img/banner13.jpg';
import hero14 from '../PageBuilder/SectionBuilder/SectionHero/img/banner14.jpg';
import hero15 from '../PageBuilder/SectionBuilder/SectionHero/img/banner15.jpg';
import hero16 from '../PageBuilder/SectionBuilder/SectionHero/img/banner16.jpg';
import hero17 from '../PageBuilder/SectionBuilder/SectionHero/img/banner17.jpg';
import hero18 from '../PageBuilder/SectionBuilder/SectionHero/img/banner18.jpg';
import hero19 from '../PageBuilder/SectionBuilder/SectionHero/img/banner19.jpg';
import hero20 from '../PageBuilder/SectionBuilder/SectionHero/img/banner20.jpg';
import hero21 from '../PageBuilder/SectionBuilder/SectionHero/img/banner21.jpg';
import hero22 from '../PageBuilder/SectionBuilder/SectionHero/img/banner22.jpg';
import hero23 from '../PageBuilder/SectionBuilder/SectionHero/img/banner23.jpg';
import hero24 from '../PageBuilder/SectionBuilder/SectionHero/img/banner24.jpg';
import hero25 from '../PageBuilder/SectionBuilder/SectionHero/img/banner25.jpg';
import hero26 from '../PageBuilder/SectionBuilder/SectionHero/img/banner26.jpg';
import hero27 from '../PageBuilder/SectionBuilder/SectionHero/img/banner27.jpg';
import hero28 from '../PageBuilder/SectionBuilder/SectionHero/img/banner28.jpg';
import hero29 from '../PageBuilder/SectionBuilder/SectionHero/img/banner29.jpg';
import hero30 from '../PageBuilder/SectionBuilder/SectionHero/img/banner30.jpg';
import hero31 from '../PageBuilder/SectionBuilder/SectionHero/img/banner31.jpg';
import hero32 from '../PageBuilder/SectionBuilder/SectionHero/img/banner32.jpg';
import hero33 from '../PageBuilder/SectionBuilder/SectionHero/img/banner33.jpg';
import hero34 from '../PageBuilder/SectionBuilder/SectionHero/img/banner34.jpg';
import hero35 from '../PageBuilder/SectionBuilder/SectionHero/img/banner35.jpg';
import hero36 from '../PageBuilder/SectionBuilder/SectionHero/img/banner36.jpg';



const Our = 'https://eeventstorage.blob.core.windows.net/oursection/Our.png';
const Wedding = 'https://eeventstorage.blob.core.windows.net/oursection/wedding.svg';
const Marriage = 'https://eeventstorage.blob.core.windows.net/oursection/Marriage.svg';
const WeddingReception = 'https://eeventstorage.blob.core.windows.net/oursection/WeddingReception.svg';
const Birthday = 'https://eeventstorage.blob.core.windows.net/oursection/Birthday.svg';
const Graduation = 'https://eeventstorage.blob.core.windows.net/oursection/Graduation.svg';
const BridalShower = 'https://eeventstorage.blob.core.windows.net/oursection/BridalShower.svg';
const BabyShower = 'https://eeventstorage.blob.core.windows.net/oursection/BabyShower.svg';
const Anniversary = 'https://eeventstorage.blob.core.windows.net/oursection/Anniversary.svg';
const Engagement = 'https://eeventstorage.blob.core.windows.net/oursection/Engagement.svg';
const Retirement = 'https://eeventstorage.blob.core.windows.net/oursection/Retirement.svg';
const HouseWarming = 'https://eeventstorage.blob.core.windows.net/oursection/HouseWarming.svg';
const CocktailParty = 'https://eeventstorage.blob.core.windows.net/oursection/CocktailParty.svg';
const CorporateEvent = 'https://eeventstorage.blob.core.windows.net/oursection/CorporateEvent.svg';
const Christmas = 'https://eeventstorage.blob.core.windows.net/oursection/Christmas.svg';
const Religious = 'https://eeventstorage.blob.core.windows.net/oursection/Religious.svg';
const BachelorParty = 'https://eeventstorage.blob.core.windows.net/oursection/BachelorParty.svg';
const BacheloretteParty = 'https://eeventstorage.blob.core.windows.net/oursection/BacheloretteParty.svg';
const NewYear = 'https://eeventstorage.blob.core.windows.net/oursection/NewYear.svg';
const Halloween = 'https://eeventstorage.blob.core.windows.net/oursection/Halloween.svg';
const Reunion = 'https://eeventstorage.blob.core.windows.net/oursection/Reunion.svg';
const Formals = 'https://eeventstorage.blob.core.windows.net/oursection/Formals.svg';
const HensNight = 'https://eeventstorage.blob.core.windows.net/oursection/HensNight.svg';
const BucksNight = 'https://eeventstorage.blob.core.windows.net/oursection/BucksNight.svg';
const NamingCeremony = 'https://eeventstorage.blob.core.windows.net/oursection/NamingCeremony.svg';
const CommitmentCeremony = 'https://eeventstorage.blob.core.windows.net/oursection/CommitmentCeremony.svg';
const Photoshoot = 'https://eeventstorage.blob.core.windows.net/oursection/Photoshoot.svg';
const TradeShow = 'https://eeventstorage.blob.core.windows.net/oursection/TradeShow.svg';
const CharityEvent = 'https://eeventstorage.blob.core.windows.net/oursection/CharityEvent.svg';
const VIPReception = 'https://eeventstorage.blob.core.windows.net/oursection/VIPReception.svg';
const PrivateDinner = 'https://eeventstorage.blob.core.windows.net/oursection/PrivateDinner.svg';
const TeamBuilding = 'https://eeventstorage.blob.core.windows.net/oursection/TeamBuilding.svg';
const FamilyFunDay = 'https://eeventstorage.blob.core.windows.net/oursection/FamilyFunDay.svg';
const HennaParty = 'https://eeventstorage.blob.core.windows.net/oursection/HennaParty.svg';
const Festival = 'https://eeventstorage.blob.core.windows.net/oursection/Festival.svg';
const JustGetTogether = 'https://eeventstorage.blob.core.windows.net/oursection/JustGetTogether.svg';
const Highlight = 'https://eeventstorage.blob.core.windows.net/oursection/highlight.png';
const Makeup = 'https://eeventstorage.blob.core.windows.net/oursection/Makeup-and-Hair-Artists.jpg';
const Photographers = 'https://eeventstorage.blob.core.windows.net/oursection/Photographers-Videographers.jpg';
const Cook = 'https://eeventstorage.blob.core.windows.net/oursection/cook-chef.jpg';
const Cakes = 'https://eeventstorage.blob.core.windows.net/oursection/cakes.jpg';
const HennaArtists = 'https://eeventstorage.blob.core.windows.net/oursection/henna-artists.jpg';
const Venue = 'https://eeventstorage.blob.core.windows.net/oursection/venue.jpg';
const Decorators = 'https://eeventstorage.blob.core.windows.net/oursection/decorators.jpg';
const Catering = 'https://eeventstorage.blob.core.windows.net/oursection/catering.jpg';
const EventPlanner = 'https://eeventstorage.blob.core.windows.net/oursection/eventplanner.jpg';
const Entertainer = 'https://eeventstorage.blob.core.windows.net/oursection/entertainer.jpg';
const Hospitality = 'https://eeventstorage.blob.core.windows.net/oursection/hospitality.jpg';
const Celebrant = 'https://eeventstorage.blob.core.windows.net/oursection/celebrant.jpg';
const Transportation = 'https://eeventstorage.blob.core.windows.net/oursection/transportation.jpg';
const PartyProps = 'https://eeventstorage.blob.core.windows.net/oursection/partyprops.jpg';


const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error, listingsLocationCount } = props;
  const [loading, setLoading] = useState(true);

  // List of images (can be from public folder or imported)
  const imageUrls = [
    image1,  // If imported, otherwise: '/assets/image1.jpg' for public folder
    image2,
    image3,
    hero4,
    hero5,
    hero6,
    hero7,
    hero8,
    hero10,
    hero11,
    hero12,
    hero13,
    hero14,
    hero15,
    hero16,
    //hero17,
    hero18,
    hero19,
    hero20,
    hero21,
    //hero22,
    hero23,
    hero24,
    hero25,
    //hero26,
    hero27,
    //hero28,
    //hero29,
    hero30,
    hero31,
    //hero32,
    hero33,
    hero34,
    hero35,
    hero36,
    Our, Wedding, Marriage, WeddingReception, Birthday, Graduation, BridalShower, BabyShower, Anniversary, Engagement, Retirement, HouseWarming, CocktailParty, CorporateEvent, Christmas, Religious, BachelorParty, BacheloretteParty, NewYear, Halloween, Reunion, Formals, HensNight, BucksNight, NamingCeremony, CommitmentCeremony, Photoshoot, TradeShow, CharityEvent, VIPReception, PrivateDinner, TeamBuilding, FamilyFunDay, HennaParty, Festival, JustGetTogether, Highlight, Makeup, Photographers, Cook, Cakes, HennaArtists, Venue, Decorators, Catering, EventPlanner, Entertainer, Hospitality, Celebrant, Transportation, PartyProps
  ];

  // Function to preload images
  const preloadImages = (imageUrls) => {
    const promises = imageUrls.map((url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    return Promise.all(promises);
  };

  useEffect(() => {
    // Preload images and hide skeleton once all images are loaded
    preloadImages(imageUrls).then(() => {
    });
  }, []);
  return (
    <div>
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
        listingsLocationCount={listingsLocationCount}
      />
    </div>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  averageRatingsListings: object,
  bookingsCountListings: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};

  const { listingsLocationCount } = state.LandingPage;

  return { pageAssetsData, inProgress, error, listingsLocationCount };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
